import { Namespace } from '@prisma/client';

import { BaseResponse, HttpRequest } from './fetch-methods';
import generateUrl from './utils/url-generator';
import { BaseService } from './base-service';

type PostNamespaceBody = {
    documents: Blob[];
    deletedDocs?: string[];
    chunkSize?: number;
    topK?: number;
    useDynamicFilters?: boolean;
};

export interface NamespaceService {
    getNamespace(clientId: string): Promise<BaseResponse<Namespace>>;
    postNamespace(clientName: string, body: PostNamespaceBody): Promise<BaseResponse<Namespace>>;
}

export class NamespaceServiceImp extends BaseService implements NamespaceService {
    private httpRequest: HttpRequest;

    constructor(httpRequest: HttpRequest) {
        super('Namespace-Service');
        this.httpRequest = httpRequest;
    }

    async getNamespace(clientId: string): Promise<BaseResponse<Namespace>> {
        return this.tryRequest(() =>
            this.httpRequest.get<Namespace>({
                url: generateUrl(`api/client/namespace`, { clientId })
            })
        );
    }

    async postNamespace(
        clientName: string,
        body: PostNamespaceBody
    ): Promise<BaseResponse<Namespace>> {
        const form = new FormData();

        form.append('client', clientName);
        form.append('chunkSize', (body.chunkSize || '').toString());
        form.append('topK', (body.topK || '').toString());
        form.append('useDynamicFilters', (body.useDynamicFilters || '').toString());

        if (Array.isArray(body.documents) && body.documents.length)
            body.documents.forEach((document: Blob, index: number) =>
                form.append(`document${index + 1}`, document)
            );

        if (Array.isArray(body.deletedDocs) && body.deletedDocs.length)
            form.append('deletedDocs', JSON.stringify(body.deletedDocs));

        return await this.tryRequest(async () =>
            this.httpRequest.post({ url: generateUrl(`api/client/namespace`), body: form })
        );
    }
}
