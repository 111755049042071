import { ChatLabel } from '@prisma/client';
import Title from '../atoms/title';
import { useState } from 'react';
import LabelPreview from '../atoms/label-preview';
import ColorSelection from '../atoms/color-selection';
import BasicButton, {
    BasicButtonType,
    IconPosition
} from '@/app/components/molecules/basic-button';
import Divider from '../atoms/divider';
import { cn } from '@/app/utils/cn';
import { useGlobalContext } from '@/app/contexts/provider';
import useUser from '@/app/contexts/userContext';
import { useModalManager } from '@/app/contexts/modalManagerContext';
import { getRandomColor } from '@/consts/label-colors';
import { useLoadingHash } from '@/app/hooks/useLoadingHash';
import AbsoluteLoading from '@/app/components/molecules/loading-absolute';
import { BaseResponse } from '@/app/services/fetch-methods';

interface LabelConfigurationProps {
    label?: ChatLabel;
    onClose: () => void;
    onCreate: (newLabel: ChatLabel) => void;
    onDelete: (labelId: string) => void;
    onEdit: (editLabel: ChatLabel) => void;
}

const LOADING = 'loading';

const LabelConfiguration = ({
    label,
    onClose,
    onCreate,
    onDelete,
    onEdit
}: LabelConfigurationProps) => {
    const [title, setTitle] = useState(label?.title || '');
    const [color, setColor] = useState(label?.color || getRandomColor());
    const { startLoading, stopLoading, isLoading } = useLoadingHash(false);

    const { chatLabelService } = useGlobalContext();
    const { showModal } = useModalManager('commons');

    const { client } = useUser();

    function onSelectedColor(color: string) {
        setColor(color);
    }

    function onClearColor() {
        setColor('#FFFFFF');
    }

    function showMissingClientIdModal() {
        showModal('alert', {
            title: 'Falta el client id',
            messages: [],
            onCloseText: 'Aceptar',
            onClose
        });
    }

    function showErrorModal(title: string, error?: string) {
        showModal('alert', {
            title,
            messages: error ? [error] : [],
            onCloseText: 'Aceptar',
            onClose
        });
    }

    async function handleLabelAction<T>(
        action: () => Promise<BaseResponse<T>>,
        successCallback: (data: T) => void,
        actionType: 'crear' | 'editar' | 'eliminar'
    ) {
        if (!client?.id) {
            showMissingClientIdModal();
            return;
        }

        startLoading(LOADING);

        try {
            const response = await action();
            if (response.error) {
                showErrorModal(`Hubo un error al ${actionType} la etiqueta.`, response.error);
                return;
            }
            successCallback(response.body);
        } finally {
            stopLoading(LOADING);
        }
    }

    async function createLabel() {
        handleLabelAction(
            () => chatLabelService.createLabel(client!.id, title, color),
            onCreate,
            'crear'
        );
    }

    async function editLabel() {
        handleLabelAction(
            () => chatLabelService.editLabel(client!.id, label!.id, { title, color }),
            onEdit,
            'editar'
        );
    }

    async function deleteLabel() {
        showModal('confirmation', {
            onConfirm: () => {
                handleLabelAction(
                    () => chatLabelService.deleteLabel(client!.id, label!.id),
                    () => onDelete(label!.id),
                    'eliminar'
                );
            },
            onConfirmText: 'Aceptar',
            messages: ['¿Desea avanzar?'],
            title: 'Al eliminar la etiqueta, la misma será removida de todos los chats en los que se encontraba asignada.'
        });
    }

    return (
        <div className={'absolute h-auto z-[60] bg-white right-[297px] shadow-form'}>
            <div className="relative flex flex-col h-full w-[400px] bg-white">
                <Title
                    title="Etiquetas"
                    onClosePressed={onClose}
                    classname="px-[23px] py-[10px] h-auto"
                />

                <LabelPreview title={title} color={color} />

                <div className="flex flex-col px-[16px] pt-[10px] pb-[20px]">
                    <p className="text-[#606060] font-medium mb-[8px]">Título</p>
                    <input
                        className="py-[14px] px-[19px] rounded-[4px] border-[2px] border-[#707070] border-opacity-40"
                        type="text"
                        value={title}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setTitle(event.target.value);
                        }}
                    />
                    <p className="text-[#606060] font-medium mt-[14px] mb-[15px]">
                        Selecciona un color
                    </p>

                    <ColorSelection
                        onSelectedColor={onSelectedColor}
                        currentSelectedColor={color}
                    />

                    <BasicButton
                        styleType={BasicButtonType.GRAY}
                        text="Quitar color"
                        icon="/icons/close-black.svg"
                        iconPosition={IconPosition.LEFT}
                        onClick={onClearColor}
                        className="mt-[18px]"
                    />

                    <Divider classname="my-[20px]" />

                    <div
                        className={cn('flex w-full ', label ? 'justify-between' : 'justify-center')}
                    >
                        {label && (
                            <BasicButton
                                styleType={BasicButtonType.RED}
                                text="Eliminar"
                                onClick={deleteLabel}
                                className="min-w-[152px]"
                            />
                        )}

                        <BasicButton
                            styleType={BasicButtonType.PRIMARY}
                            text={label ? 'Guardar' : 'Crear'}
                            onClick={label ? editLabel : createLabel}
                            className="min-w-[152px]"
                        />
                    </div>
                </div>

                {isLoading && <AbsoluteLoading />}
            </div>
        </div>
    );
};

export default LabelConfiguration;
