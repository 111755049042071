import LoadingDots from '@/app/components/atoms/loading-dots';
import Icon from '@/app/components/molecules/icon';
import { SendIcon } from '@/app/icons';
import { cn } from '@/app/utils/cn';
import { useMediaQuery } from '@mui/material';

interface InputButtonProp extends React.ComponentPropsWithoutRef<'button'> {
    loading?: boolean;
    isPreview?: boolean;
}

const InputButton = ({
    loading = false,
    isPreview = false,
    disabled,
    style,
    ...rest
}: InputButtonProp) => {
    const sm = useMediaQuery('(max-width: 640px)');
    const md = useMediaQuery('(max-width: 1024px)');

    return (
        <button
            type="submit"
            disabled={loading || disabled}
            {...rest}
            className={cn(
                'aspect-square rounded-[6px] flex items-center justify-center p-1',
                'hover:opacity-90',
                rest.className
            )}
            style={style}
        >
            {loading ? (
                <LoadingDots color={style?.color} />
            ) : (
                <div className="relative h-full aspect-square flex items-center justify-center">
                    <Icon i={SendIcon} size={isPreview ? '2xs' : sm ? '2xs' : md ? 'md' : 'lg'} />
                </div>
            )}
        </button>
    );
};

export default InputButton;
