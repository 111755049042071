import Image from 'next/image';
import React, { ReactNode } from 'react';

import modalStyles from '@/app/styles/modal.module.css';
import { cn } from '@/app/utils/cn';

interface Text {
    text: string;
    size?: string;
    weight?: string;
    color?: string;
}

interface ActionButton {
    message: Text;
    action: () => void;
    disabled?: boolean;
}

type Icon =
    | {
          name: string;
          width: number;
          height: number;
      }
    | { content: ReactNode };

export interface ModalClassNames {
    mainContainer?: string;
    fixedContainer?: string;
    modalContainer?: string;
}

interface ModalProps {
    title?: Text;
    messages: ReactNode[];
    actions?: ActionButton[];
    useIcon?: boolean;
    icon?: Icon;
    maxWidth?: string;
    onClose?: () => void;
    classNames?: ModalClassNames;
    children?: React.ReactNode;
}

const defaultAction = [{ message: { text: 'Aceptar' }, action: () => {} }];
const defaultIcon = { name: 'warning', width: 64, height: 64 };

const Modal: React.FC<ModalProps> = ({
    title,
    messages,
    actions = defaultAction,
    useIcon = true,
    icon = defaultIcon,
    maxWidth,
    onClose,
    classNames,
    children
}) => {
    const styles = {
        '--modal-max-width': maxWidth,
        '--title-size': title?.size,
        '--title-weight': title?.weight,
        '--title-color': title?.color
    } as any;

    return (
        <div
            style={styles}
            className={cn('absolute z-[60] inset-0 overflow-y-auto', classNames?.mainContainer)}
        >
            <div className="fixed block items-end justify-center min-h-screen">
                <div
                    className={cn(
                        'fixed inset-0 bg-[#00000033] transition-opacity',
                        classNames?.fixedContainer
                    )}
                    onClick={onClose}
                />

                <div
                    className={cn(
                        modalStyles.container,
                        'fixed inline-block w-[90%] sm:w-auto bg-white rounded-[13px] top-1/2 -translate-y-[60%] left-1/2 transform -translate-x-1/2 px-3 pt-5 pb-4 text-left overflow-hidden shadow-modal transition-all sm:p-6',
                        classNames?.modalContainer
                    )}
                >
                    <div className="flex flex-col items-center text-center">
                        {useIcon &&
                            ('content' in icon
                                ? icon.content
                                : icon.name && (
                                      <Image
                                          height={icon.height}
                                          width={icon.width}
                                          src={`/icons/${icon.name}.svg`}
                                          alt="modal-icon"
                                      />
                                  ))}

                        {title && (
                            <h1 className={`${modalStyles.title} mt-3 mb-1`}>{title.text}</h1>
                        )}

                        {messages.map((msg, index) => (
                            <div key={`message-${index}`}>
                                {typeof msg === 'string' ? (
                                    <p className="text-sm text-gray-70 mb-2">{msg}</p>
                                ) : (
                                    msg // Render as is if it's a ReactNode
                                )}
                            </div>
                        ))}

                        {children}

                        <div className="flex justify-evenly flex-nowrap mt-4 gap-x-6">
                            {actions?.map((button: ActionButton, index: number) => (
                                <button
                                    key={`action-${index}`}
                                    className="w-40 disabled:cursor-not-allowed"
                                    onClick={button.action}
                                    disabled={button.disabled}
                                >
                                    <span
                                        className={`text-${button.message.color || 'black'} font-weight-${button.message.weight || 400} text-sm`}
                                        style={{
                                            fontSize: `${button.message.size || '14px'}`
                                        }}
                                    >
                                        {button.message.text}
                                    </span>
                                </button>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Modal;
