import { ComponentPropsWithRef, ComponentPropsWithoutRef, Ref, forwardRef, useId } from 'react';

export interface IconProps extends Omit<ComponentPropsWithoutRef<'svg'>, 'size'> {
    i: IconFC;
    /**
     * @property 2xs: 16px
     * @property xs: 18px
     * @property sm: 20px
     * @property md: 24px
     * @property lg: 28px
     * @property xl: 32px
     * @property 2xl: 40px
     */
    size?: keyof typeof iconSizes;
}

export type IconFC = React.FC<
    ComponentPropsWithRef<'svg'> & {
        idprefix?: string;
        idSuffix?: string;
    }
>;

const Icon = forwardRef(({ i: I, size = 'md', ...rest }: IconProps, ref: Ref<any>) => {
    const id = useId();
    return <I {...styleBySize(size)} viewBox="0 0 24 24" ref={ref} idprefix={id} {...rest} />;
});

Icon.displayName = 'Icon';

// Standard icon sizes
const iconSizes = {
    '2xs': 16,
    xs: 18,
    sm: 20,
    md: 24,
    lg: 28,
    xl: 32,
    '2xl': 40
};

const styleBySize = (size: keyof typeof iconSizes) => ({
    height: iconSizes[size],
    width: iconSizes[size]
});

export default Icon;
