import { BaseService } from './base-service';
import { BaseResponse, HttpRequest } from './fetch-methods';
import generateUrl from './utils/url-generator';

export interface ConvertService {
    convertXlsxToCsv(
        prompt: string,
        file: File,
        columnNames: string[],
        schemaStructure: string[],
        useCache: boolean,
        email: string
    ): Promise<BaseResponse<{ message: string }>>;
}

export class ConvertServiceImp extends BaseService implements ConvertService {
    private httpRequest: HttpRequest;

    constructor(httpRequest: HttpRequest) {
        super('Convert-Service');
        this.httpRequest = httpRequest;
    }

    async convertXlsxToCsv(
        prompt: string,
        file: File,
        columnNames: string[],
        schemaStructure: string[],
        useCache: boolean,
        email: string
    ): Promise<BaseResponse<{ message: string }>> {
        const form = new FormData();
        form.append('prompt', prompt);
        form.append('columnNames', columnNames.join(','));
        form.append('schemaStructure', schemaStructure.join(','));
        form.append('useCache', useCache.toString());
        form.append('mail', email);
        form.append('file', file);

        return await this.tryRequest(async () =>
            this.httpRequest.post({ url: generateUrl(`api/convert/xlsx/csv`), body: form })
        );
    }
}

export default ConvertServiceImp;
