import { Document } from '@langchain/core/documents';

import { Message, Chat } from '@prisma/client';

import { PaginatedData } from '@/types/paginatedData';

import { BaseResponse, HttpRequest } from './fetch-methods';
import generateUrl from './utils/url-generator';
import { BaseService } from './base-service';
import { UpdateChat } from '@/services/model/chatService';
import { TimePeriod } from '@/enums/time-period.enum';
import { IntervalData } from '@/app/pages/analytics/molecules/statistics';

export type QuestionInfo = {
    client: string;
    from: string;
    question: string;
    history: [string, string][];
    file?: File;
};

export type QuestionAnswer = {
    data: string;
    sourceDocs: Document[];
    filter: Record<string, any>;
};

export interface ChatService {
    getChat(chatId: string): Promise<BaseResponse<Chat>>;
    getMessagesFromChat(
        chatId: string,
        pageable?: Pageable
    ): Promise<BaseResponse<PaginatedData<Message>>>;
    getChats(criteria?: {
        page?: number;
        pageSize?: number;
        input?: string;
        clientId?: string;
        timestamp?: number;
        interactiveChatsOnly?: boolean;
    }): Promise<BaseResponse<PaginatedData<Chat>>>;
    getChatsStatistics(timePeriod: TimePeriod): Promise<BaseResponse<IntervalData>>;
    countInteractiveChats(clientId: string): Promise<BaseResponse<Number>>;
    sendQuestion(questionInfo: QuestionInfo): Promise<void>;
    updateChat(chatId: string, body: UpdateChat): Promise<BaseResponse<Chat>>;
    updateState(chatId: string, stage: string): Promise<BaseResponse<Chat>>;
    updateWebchat(chatId: string, body: UpdateChat): Promise<BaseResponse<Chat>>;
}

export class ChatServiceImp extends BaseService implements ChatService {
    private httpRequest: HttpRequest;

    constructor(httpRequest: HttpRequest) {
        super('Chat-Service');
        this.httpRequest = httpRequest;
    }

    countInteractiveChats(clientId: string): Promise<BaseResponse<any>> {
        const request = () =>
            this.httpRequest.get<Chat>({
                url: generateUrl(`api/chat/interactive/count`, { clientId })
            });

        return this.tryRequest<Chat>(request);
    }

    updateChat(chatId: string, body: UpdateChat): Promise<BaseResponse<Chat>> {
        return this.tryRequest<Chat>(() =>
            this.httpRequest.patch<Chat>({
                url: generateUrl(`api/chat/${chatId}`),
                body
            })
        );
    }

    updateWebchat(chatId: string, body: UpdateChat): Promise<BaseResponse<Chat>> {
        return this.tryRequest<Chat>(() =>
            this.httpRequest.patch<Chat>({
                url: generateUrl(`api/webchat/${chatId}`),
                body
            })
        );
    }

    getChat(chatId: string): Promise<BaseResponse<Chat>> {
        const request = () =>
            this.httpRequest.get<Chat>({
                url: generateUrl(`api/chat/${chatId}`)
            });
        return this.tryRequest<Chat>(request);
    }

    getMessagesFromChat(
        chatId: string,
        pageable: Pageable = {
            page: 1,
            pageSize: 20,
            sortBy: 'createdAt',
            sortOrder: 'desc'
        }
    ): Promise<BaseResponse<PaginatedData<Message>>> {
        pageable.sortOrder = pageable.sortOrder || 'desc';
        const request = () =>
            this.httpRequest.get<PaginatedData<Message>>({
                url: generateUrl(`api/message`, { chatId, ...pageable })
            });
        return this.tryRequest<PaginatedData<Message>>(request);
    }

    getChats(criteria?: {
        page?: number;
        pageSize?: number;
        input?: string;
        clientId?: string;
        timestamp?: number;
    }): Promise<BaseResponse<PaginatedData<Chat>>> {
        const request = () =>
            this.httpRequest.get<PaginatedData<Chat>>({
                url: generateUrl(`api/chat`, criteria)
            });
        return this.tryRequest<PaginatedData<Chat>>(request);
    }

    getChatsStatistics(timePeriod: TimePeriod): Promise<BaseResponse<IntervalData>> {
        const request = () =>
            this.httpRequest.get<IntervalData>({
                url: generateUrl('api/chat/statistics', { timePeriod })
            });
        return this.tryRequest<IntervalData>(request);
    }

    async sendQuestion(questionInfo: QuestionInfo) {
        const form = new FormData();
        form.append('question', questionInfo.question);
        form.append('client', questionInfo.client);
        form.append('from', questionInfo.from);
        form.append('history', JSON.stringify(questionInfo.history)); // TODO: see this field, back is not using it anymore
        if (questionInfo.file) form.append('file', questionInfo.file);

        this.httpRequest.post({
            url: '/api/webchat/message',
            body: form
        });
    }

    async updateState(chatId: string, state: string): Promise<BaseResponse<Chat>> {
        const request = () =>
            this.httpRequest.patch<Chat>({
                url: generateUrl(`api/chat/${chatId}/state`),
                body: { state }
            });
        return this.tryRequest<Chat>(request);
    }
}
