import Modal from '@/app/components/organisms/modal/modal';
import { ModalImpProps } from '@/app/contexts/modalManagerContext';
import { WifiOff } from 'lucide-react';

export default function Offline({
    classNames,
    messages,
    title,
    onClose = () => {}
}: ModalImpProps) {
    return (
        <Modal
            key="offline"
            messages={
                messages || [
                    'Se perdió la conexión',
                    'Por favor revisar el acceso a internet y recargar la conversación.'
                ]
            }
            icon={{
                content: (
                    <div className="bg-[#f0f0f0] rounded-full w-16 h-16 flex justify-center items-center">
                        <WifiOff className="text-[#6c6c6c]" size={30} />
                    </div>
                )
            }}
            title={(title && { text: title }) || { text: 'Baja conectividad' }}
            actions={[
                {
                    message: { text: 'Aceptar', color: 'gray-62', weight: '500' },
                    action: onClose
                }
            ]}
            classNames={classNames}
        />
    );
}
