export const adminPrefix = '/admin';
export const authPrefix = '/auth';

export enum Routes {
    INDEX = '/',
    CHATS = '/',
    CHAT_PAGE = '/chat-page',
    WEBCHAT = '/webchat',
    FASHION_CHAT = '/fashionchat',
    LOGIN = authPrefix + '/login',
    REGISTER = authPrefix + '/register',
    ACTIVATE = authPrefix + '/activate',
    AUTH_MESSAGE = authPrefix + '/message',
    CLIENTS = adminPrefix + '/clients',
    NEW_CLIENT = adminPrefix + '/clients/new',
    USAGE = adminPrefix + '/usage',
    DATA_ENRICHMENT = adminPrefix + '/data-enrichment',
    DASHBOARD = '/dashboard',
    INSIGHTS = '/insights',
    ANALYTICS = '/analytics',
    CONVERSION_URL = '/conversion-url',
    TUTORIAL = '/tutorial',
    PRESET_MESSAGES = '/preset-messages',
    SUGGESTED_QUESTIONS = '/suggested-questions',
    TIENDANUBE = '/integrations/tiendanube',
    SHOPIFY = '/integrations/shopify',
    NOT_SUPPORTED = '/not-supported' // TODO: Remove this enum when the app is fully responsive.
}
